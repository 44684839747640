import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

export interface MathemaModalConfirmParams {
  header: string;
  message: string;
  declineText?: string;
  acceptText: string;
  showNotification?: boolean;
}

@Component({
  selector: 'math-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class MathemaModalConfirmComponent implements OnInit {

  @Input() isLoading: boolean;

  public params: MathemaModalConfirmParams;
  public notificationNeeded = false;

  @Output() onAccept: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDecline: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: {
      params: MathemaModalConfirmParams
    }
  ) {
    this.params = data.params;
  }

  ngOnInit(): void {
  }

}
