<div class="content">
  <div class='sidebar-content'>
    {{currentUrl}}
    <div class='links'>
      <a
        class="button"
        routerLinkActive="selected"
        [routerLink]="'/' + routes.CALENDAR"
      >{{'main.navigation.calendar' | translate}}</a>
      <a *ngIf="authService.isManager() || authService.isAdmin()"
         class="button"
         routerLinkActive="selected"
         [routerLink]="'/' + routes.FREE_HOURS"
      >{{'main.navigation.free-hours-search' | translate}}</a>
      <a
        *ngIf="authService.isManager() || authService.isAdmin()"
        routerLinkActive="selected"
        class='button'
        [routerLink]="'/' + routes.USERS">{{'users.menu-title' | translate}}</a>
      <a
        *ngIf="!authService.isClient()"
        routerLinkActive="selected"
        class='button'
        [routerLink]="'/' + routes.STUDENTS">
        <span *ngIf="!authService.isTeacher(); else myStudentsNav">{{'main.navigation.students' | translate}}</span>
        <ng-template #myStudentsNav>{{'main.navigation.my-students' | translate}}</ng-template>
      </a>
      <a
        *ngIf="authService.isTeacher()"
        routerLinkActive="selected"
        class='button'
        [routerLink]="'/' + routes.VIEW_USER_INFO">{{'my_cabinet' | translate}}</a>
      <a
        *ngIf="authService.isClient()"
        routerLinkActive="selected"
        class='button'
        [routerLink]="'/' + routes.CLIENT_PROFILE">{{'main.navigation.client-profile' | translate}}</a>
      <a
        *ngIf="authService.isTeacher() && authService.isUserUkrainian()"
        class='button'
        target="_blank"
        href="https://bit.ly/4f38bqd">{{'main.navigation.study-notes' | translate}}</a>
      <a
        *ngIf="authService.isTeacher() && authService.isUserPoland()"
        class='button'
        target="_blank"
        href="https://bit.ly/4dmcgnz">{{'main.navigation.study-notes' | translate}}</a>
      <a
        *ngIf="authService.isTeacher() && authService.isUserUkrainian()"
        class="button"
        target="_blank"
        href="https://mathema.crunch.help/uk/zaghal-ni-ta-orghanizatsiini-pitannia/vzaiemodiia-vchitielia-z-inshimi-priedstavnikami-mathema"
      >
        {{'main.navigation.ua-teacher-referral-message' | translate}}
      </a>
      <a
        *ngIf="authService.isTeacherCandidate() && authService.isUserUkrainian()"
        class="button"
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSf246NRisNRXrTWFcTCMF401k5tpRYCFQgtTFlDH9_kYM-viw/viewform"
      >
        {{'main.navigation.ua-teacher-candidate-test-message' | translate}}
      </a>
      <a
        *ngIf="authService.isClient() && (authService.isUserUkrainian() || authService.isUserPoland())"
        class="button"
        target="_blank"
        href="https://www.trustpilot.com/review/mathema.me">
        {{'main.navigation.leave-review' | translate}}
      </a>
    </div>
  </div>
  <div class="profile-log-out">
    <div class="user-info-container" [class.is-teacher]="authService.isTeacher()" (click)="authService.isTeacher() ? redirectToMyAccount():''">
      <div class="user-logo">
        <img *ngIf="authService.user.avatarLink else noAvatar" [src]="authService.user.avatarLink" alt="">
        <ng-template #noAvatar>
          <img *ngIf="authService.isFemale() || authService.isMale() else noLogo" [src]="authService.isFemale() ? 'assets/NoPhotoGirl.png' : 'assets/NoPhotoBoy.png'" alt="">
        </ng-template>
        <ng-template #noLogo>
          <span>{{ authService.user?.firstName?.[0] | uppercase }}</span>
        </ng-template>
      </div>
      <div class="user-info">
      <span class="name">
        {{ authService.user.firstName }} {{ authService.user.lastName }}
      </span>
        <span class="role">
        <ng-container *ngIf="authService.isManager()">{{'main.roles.manager' | translate}}</ng-container>
        <ng-container *ngIf="authService.isAdmin()">{{'main.roles.admin' | translate}}</ng-container>
        <ng-container *ngIf="authService.isTeacher()">{{'main.roles.teacher' | translate}}</ng-container>
        <ng-container *ngIf="authService.isClient()">{{'main.roles.client' | translate}}</ng-container>
      </span>
      </div>
    </div>
    <div class="log-out-container">
      <button class="log-out" (click)='onLogoutClick()'>
        {{'log_out' | translate}}
      </button>
    </div>
  </div>
</div>


