import { Component, Inject, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import PifagorApiService from '../../services/pifagor-api.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'math-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {

  @Input() isLoading: boolean;

  public form: UntypedFormGroup;
  public showPasswords = false;

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly pifagorApi: PifagorApiService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    @Inject(NZ_MODAL_DATA) public data: { userId: string }
  ) {
    this.form = new UntypedFormGroup({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      checkPassword: new UntypedFormControl('', [Validators.required, this.confirmationValidator]),
    });
  }

  public onSubmit(): void {
    this.isLoading = true;

    this.pifagorApi.changePassword(this.data.userId, {
      oldPassword: this.form.value.oldPassword,
      newPassword: this.form.value.newPassword,
    }).pipe(finalize(() => {
      this.isLoading = false;
    })).subscribe(() => {
      this.modalRef.close();
      this.notificationService.showSuccessMessage(this.translateService.instant('change-password.success'));
    });
  }

  public updateConfirmValidator(): void {
    Promise.resolve().then(() => this.form.controls.checkPassword.updateValueAndValidity());
  }

  private confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls.newPassword.value) {
      return { confirm: true, error: true };
    }

    return {};
  };

}
