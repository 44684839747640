import { Pipe, PipeTransform } from '@angular/core';
import LanguageService from '@shared/language/language.service';

@Pipe({
  name: 'customDateFormat'
})
export class CustomDateFormatPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(
    value: string,
  ): unknown {
    const date = new Date(value);

    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    const [day, month, year] = formatter.format(date).split(' ');
    return [day, month, year].join(' ');
  }

}
